import Input from '@components/input';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import style from './index.module.scss';

const CanceledCheck: FC = () => {
	const { kassa } = useStore();
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
			<div className={style.row}>
				<div>Наличные</div>
				<div>
					<Input
						style={{ height: 38, width: '100%' }}
						isNumber
						value={kassa.reportFull?.cash_canceled?.toString() || ''}
						onChange={(e) => kassa.reportFull?.setCashCanceled(+e.target.value)}
						disabled={kassa.reportFull?.readonly}
					/>
				</div>
			</div>
			<div className={style.row}>
				<div>Карта</div>
				<div>
					<Input
						style={{ height: 38, width: '100%' }}
						isNumber
						value={kassa.reportFull?.card_canceled?.toString() || ''}
						onChange={(e) => kassa.reportFull?.setCardCanceled(+e.target.value)}
						disabled={kassa.reportFull?.readonly}
					/>
				</div>
			</div>
			<div className={style.row}>
				<div>Сертификат</div>
				<div>
					<Input
						style={{ height: 38, width: '100%' }}
						isNumber
						value={kassa.reportFull?.certificate_canceled?.toString() || ''}
						onChange={(e) => kassa.reportFull?.setCertificateCanceled(+e.target.value)}
						disabled={kassa.reportFull?.readonly}
					/>
				</div>
			</div>
		</div>
	);
};

export default observer(CanceledCheck);
