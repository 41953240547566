import { SnapshotIn, types } from 'mobx-state-tree';

const { model, array, boolean, number, string, union, literal } = types;

const BagsCollectModel = model('bags', {
	id: number,
	name: string,
	order_id: number,
	order_market_id: number,
	store_place_full_name: string,
	is_collected: boolean,
});

const OrdersCollectBags = model('order-collect-bags', {
	order_ids: array(number),
	receiver_fio: string,
	count: number,
	bags: array(BagsCollectModel),
});

export default OrdersCollectBags;

export type IOrdersCollectBags = SnapshotIn<typeof OrdersCollectBags>;
export type IBagsCollectModel = SnapshotIn<typeof BagsCollectModel>;
