import AuthProvider from '@components/auth';
import { ErrorBoundary } from '@components/error';
import Auth from '@pages/auth';
import { Compensations } from '@pages/index';
import Main from '@pages/main';
import Acceptance from '@pages/main/acceptance';
import { default as EmallAcceptance } from '@pages/main/acceptance/components/emall';
import PostalAcceptance from '@pages/main/acceptance/components/postal';
import { default as RouteSheetAcceptance } from '@pages/main/acceptance/components/route-sheet';
import ExtendedSearch from '@pages/main/extended-search';
import Identification from '@pages/main/identification';
import Kassa from '@pages/main/kassa';
import Reports from '@pages/main/reports';
import Shipment from '@pages/main/shipment';
import Emall from '@pages/main/shipment/components/emall';
import Post from '@pages/main/shipment/components/post';
import RouteSheet from '@pages/main/shipment/components/route-sheet';
import Tasks from '@pages/main/tasks';
import EmallTasks from '@pages/main/tasks/components/emall';
import MailTasks from '@pages/main/tasks/components/mail';
import Visits from '@pages/main/visits';
import Visit from '@pages/main/visits/components/visit';
import Notification from '@pages/notification';
import { Navigate, RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
	{
		path: '/',
		element: (
			<AuthProvider>
				<Main />
			</AuthProvider>
		),
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: 'visits',
				element: <Visits />,
				children: [
					{ path: ':visit', element: <Visit /> },
					{ index: true, element: <Navigate to={'1'} /> },
				],
			},
			{
				path: 'tasks',
				element: <Tasks />,
				children: [
					{ index: true, element: <Navigate to={'emall'} /> },
					{ path: 'emall', element: <EmallTasks /> },
					{ path: 'mail', element: <MailTasks /> },
				],
			},
			{
				path: 'acceptance',
				element: <Acceptance />,
				children: [
					{ index: true, element: <Navigate to={'route-sheet'} /> },
					{ path: 'route-sheet', element: <RouteSheetAcceptance /> },
					{ path: 'emall', element: <EmallAcceptance /> },
					{ path: 'postal', element: <PostalAcceptance /> },
				],
			},
			{
				path: 'shipment',
				element: <Shipment />,
				children: [
					{ index: true, element: <Navigate to={'route-sheet'} /> },
					{ path: 'route-sheet', element: <RouteSheet /> },
					{ path: 'post', element: <Post /> },
					{ path: 'emall', element: <Emall /> },
				],
			},
			{
				path: 'compensations',
				element: <Compensations />,
			},
			{ path: 'logistics' },
			{ path: 'reports', element: <Reports /> },
			{ path: 'inventory' },
			{
				path: 'kassa',
				element: <Kassa />,
			},
			{
				path: 'identification',
				element: <Identification />,
			},
			{
				path: 'extended-search',
				element: <ExtendedSearch />,
			},
		],
	},
	{ path: '/auth/:token/:login', element: <Auth /> },
	{
		path: 'notification/:date/:id',
		element: (
			<AuthProvider>
				<Notification />
			</AuthProvider>
		),
	},
];

export default routes;
