import { createSocketConnection, leaveEvenMobile, listenChannelMobile } from '@services/socket';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { playAudio } from '../../utils/playSound';
import { voiceMessage } from '../../utils/voiceMessage';
import styles from './index.module.scss';

const colorStatus = {
	1: 'green',
	2: '#FADB14FF',
	3: 'red',
};

const Notification = () => {
	const { id } = useParams();
	const [data, setDataNotification] = useState<{
		status: 1 | 2 | 3;
		status_description: string;
		short_text: string;
		full_text: string;
		socket: any;
	} | null>(null);

	useEffect(() => {
		createSocketConnection(Number(id));
		listenChannelMobile(
			(data) => {
				setDataNotification(data);
				const { status } = data;
				playAudio((status === 3 && 'error') || (status === 2 && 'warning') || 'posted');
				voiceMessage({ message: data.short_text });
			},
			'.operator.mobile.scan',
			Number(id)
		);
		return () => leaveEvenMobile('.operator.mobile.scan', Number(id));
	}, []);
	return (
		<div className={styles.wrapper}>
			<span className={styles.notification}>
				{!data ? (
					<span className={styles.wrapperDots}>
						Ожидаю сканирования<span className={styles.animatedDots}></span>
					</span>
				) : (
					<>
						<span style={{ color: colorStatus[data?.status || 1] || '', textAlign: 'center' }}>
							{data?.status_description}
						</span>
						<span>{data?.full_text}</span>
					</>
				)}
			</span>
		</div>
	);
};

export default Notification;
