import { api } from '@http/index';
import { IItem, IItemsModal, IOrder, IScanningPackage } from '@store/acceptance/emall';
import { IHeader } from '@store/acceptance/header';
import {
	IItemCargoSpace,
	IItemsModal as IItemsModalPostal,
	IPostalCargoSpacesItem,
} from '@store/acceptance/postal/cargo-spaces';
import { IPostalTroubleItem } from '@store/acceptance/postal/postal-trouble';
import { Sheet } from '@store/acceptance/route-sheet';
import { IRoutingInfo, PutSpaceDataType } from '@store/routing-info';
import { IRoutingInfoByDriver } from '@store/routing-info-by-driver';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IPostPostalTroubleItem {
	id: number;
	number: string;
	status: number;
	comment?: string;
}

interface IItemCargoSpaceScan extends IItemCargoSpace {
	type: 1 | 2;
}

export interface IGetSpacesUnidentified {
	numbers: string[];
}

export interface IGetSpacesUnidentifiedResponse {
	id: number | null;
	number: string | null;
	title: string;
	description: string;
	status: 0 | 1 | 2 | 3;
}

export interface IPostUnidentifiedPostal {
	sender_fio?: string;
	sender_phone_number?: number;
	receiver_fio?: string;
	receiver_phone_number?: number;
	arrival_at?: string;
	store_id_start?: number;
	store_id_finish?: number;
	weight?: number;
	weight_fact?: number;
	external_id?: string;
	number?: string;
}

interface IPostStorePlaceItemScanBody {
	store_place_item_id_to_block: number | null;
	store_place_item_id_to_choose: number;
	item_id: number;
	routing_space_id: number;
}

interface IPostSpacesNumberScan {
	status: 1 | 2 | 3;
	message: string;
	routing_space: {
		id: number;
		type: number;
		is_scanned: 0 | 1;
		number: string;
		routing_items_count: number;
	};
}

const getLists = (showOld: 0 | 1): Promise<AxiosResponse<Sheet[]>> =>
	api.get(`/routing/acceptance/lists?show_old=${showOld}`);

const getList = (id: number): Promise<AxiosResponse<IRoutingInfo>> => api.get(`/routing/acceptance/lists/${id}`);

const getSpaces = (): Promise<AxiosResponse<IHeader>> => api.get(`/routing/acceptance/spaces`);

const getSpace = (number: string): Promise<AxiosResponse<Sheet>> =>
	api.get(`/routing/acceptance/spaces?number=${number}`);

const getDriverLists = (id: number): Promise<AxiosResponse<IRoutingInfoByDriver>> =>
	api.get(`/routing/acceptance/driver/${id}/lists`);

const getInfo = (): Promise<AxiosResponse<IHeader>> => api.get(`/routing/acceptance/info`);

const putSpaces = (id: number, data: PutSpaceDataType): Promise<AxiosResponse<void>> =>
	api.put(`/routing/acceptance/spaces/${id}`, data);

const postConfirm = (id: number): Promise<AxiosResponse<void>> =>
	api.post(`/routing/acceptance/confirm`, { driver_market_id: id });

const postListsClose = (data: { routing_list_id: number[] }): Promise<AxiosResponse<void>> =>
	api.post(`/routing/acceptance/lists/close`, data);

const getEmallItems = (): Promise<AxiosResponse<IItemsModal>> => api.get(`/routing/acceptance/emall/items/bags`);

const getEmallItemsProduct = (): Promise<AxiosResponse<IItemsModal>> =>
	api.get(`/routing/acceptance/emall/items/product`);

const getEmallOrders = (): Promise<AxiosResponse<IOrder[]>> => api.get(`/routing/acceptance/emall/orders`);

const getEmallItemName = (name: string): Promise<AxiosResponse<IItem>> =>
	api.get(`/routing/acceptance/emall/items?name=${name}`);

const getPostalCargoSpaces = (): Promise<AxiosResponse<IPostalCargoSpacesItem>> =>
	api.get(`/routing/acceptance/postal/spaces`);

const getPostalItems = (id: number): Promise<AxiosResponse<IItemsModalPostal>> =>
	api.get(`/routing/acceptance/postal/spaces/${id}/items`);

const getPostalTroubleItems = (): Promise<AxiosResponse<IPostalTroubleItem>> =>
	api.get(`/routing/acceptance/postal/trouble-items`);

const postSendTroubleItem = (data: IPostPostalTroubleItem): Promise<AxiosResponse<void>> =>
	api.post(`/routing/acceptance/postal/send-trouble-item`, data);

const postSpacesClose = (): Promise<AxiosResponse<void>> => api.post(`/routing/acceptance/emall/spaces/close-by-bags`);

const postSpacesCloseByProduct = (data: { space_id?: number; item_ids?: number[] }): Promise<AxiosResponse<void>> =>
	api.post(`/routing/acceptance/emall/spaces/close-by-products`, data);

const postSpacesNumberScan = (number: string): Promise<AxiosResponse<IPostSpacesNumberScan>> =>
	api.post(`/routing/acceptance/spaces/${number}/scan`);

const postEmallScan = (
	number: number
): Promise<
	AxiosResponse<
		| {
				type: 1;
				space: {
					id: number;
					name: string;
				};
		  }
		| {
				type: 2;
				store_place_item_id?: number | null;
				store_place_item_full_name?: string | null;
				store_place_item_text?: string | null;
				scanning_status: 1 | 2;
				item: {
					id: number;
					item_market_id: number;
					name: string;
					order_item_id?: number | null;
					order_market_id?: number | null;
				};
		  }
		| void
	>
> => api.post(`/routing/acceptance/emall/scan`, { number });

const postEmallItemScan = (data: {
	barcode: string;
	routing_space_id: number;
}): Promise<
	AxiosResponse<{
		store_place_item_id?: number | null;
		store_place_item_full_name?: string | null;
		store_place_item_text?: string | null;
		scanning_status: 1 | 2;
		item: {
			id: number;
			item_market_id: number;
			name: string;
			order_market_id?: number | null;
			order_item_id?: number | null;
		};
	}>
> => api.post(`/routing/acceptance/emall/item/scan`, data);

const postEmallBagsPlaceItemScan = (data: {
	store_place_item_id_to_block: number | null;
	store_place_item_id_to_choose: number;
	routing_item_id: number | null;
}): Promise<AxiosResponse<{ store_place_item_full_name: string }>> =>
	api.post(`/routing/acceptance/emall/bags/store-place-item/scan`, data);

const postEmallPlaceItemScan = (data: {
	store_place_item_id_to_block: number | null;
	store_place_item_id_to_choose: number;
	order_item_id: number | null;
	order_market_id: number | null;
	order_item_marking_code_id?: number | null;
}): Promise<AxiosResponse<void>> => api.post(`/routing/acceptance/emall/store-place-item/scan`, data);

const postEmallPackageScan = (number: string): Promise<AxiosResponse<IScanningPackage>> =>
	api.post(`/routing/acceptance/emall/package/scan`, { number });

const postUnidentifiedPostal = (
	data: IPostUnidentifiedPostal
): Promise<AxiosResponse<{ postal_item_id: number; unidentified_item_id: number }>> =>
	api.post('/postal/unidentified', data);

const postSpacesStartScanned = (id: number): Promise<IItemsModalPostal> =>
	api.post(`/routing/acceptance/postal/spaces/${id}/start`);

const postSpacesPauseScanned = (id: number): Promise<AxiosResponse<void>> =>
	api.post(`/routing/acceptance/postal/space/${id}/pause`);

const getSpacesUnidentified = (
	data: IGetSpacesUnidentified
): Promise<AxiosRequestConfig<IGetSpacesUnidentifiedResponse>> =>
	api.get(`/routing/acceptance/postal/spaces/unidentified`, { params: data });

const postPostalScan = (data: { number: string }): Promise<AxiosResponse<IItemsModalPostal | IItemCargoSpaceScan>> =>
	api.post('/routing/acceptance/postal/scan', data);

const postPostalScanNumber = (number: string): Promise<AxiosResponse<IItemCargoSpace>> =>
	api.post(`/routing/acceptance/postal/items/${number}/scan`);

const postStorePlaceItemsScan = (data: IPostStorePlaceItemScanBody): Promise<AxiosResponse<IItemCargoSpace>> =>
	api.post(`/routing/acceptance/postal/store-place-items/scan`, data);

const postalSpaceClose = (id: number): Promise<AxiosResponse<IItemCargoSpace>> =>
	api.post(`/routing/acceptance/postal/space/${id}/close`);

export const AcceptanceService = {
	getLists,
	getInfo,
	getList,
	getDriverLists,
	getSpaces,
	getSpace,
	putSpaces,
	postConfirm,
	postListsClose,
	getEmallItems,
	getEmallItemName,
	getEmallOrders,
	getPostalCargoSpaces,
	getPostalItems,
	getPostalTroubleItems,
	postSendTroubleItem,
	postSpacesClose,
	getEmallItemsProduct,
	postEmallScan,
	postEmallItemScan,
	postEmallPlaceItemScan,
	postSpacesCloseByProduct,
	postUnidentifiedPostal,
	postSpacesStartScanned,
	postSpacesPauseScanned,
	getSpacesUnidentified,
	postPostalScan,
	postPostalScanNumber,
	postStorePlaceItemsScan,
	postalSpaceClose,
	postEmallPackageScan,
	postSpacesNumberScan,
	postEmallBagsPlaceItemScan,
};
