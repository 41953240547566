import Button from '@components/buttons/button';
import Modal from '@components/modal';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { AcceptanceService } from '@services/acceptance';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useState } from 'react';

import { playAudio } from '../../../../../../../utils/playSound';
import Header from './components/header';
import ItemsTable from './components/table';
import styles from './index.module.scss';

const ItemsModal: FC = () => {
	const [showConfirm, setShowConfirm] = useState(false);
	const {
		notice,
		operator,
		acceptance: { emall },
		setLoading,
	} = useStore();

	const close = (): void => {
		leave();
		emall.closeModalItems();
	};

	const closeConfirm = (): void => {
		setShowConfirm(false);
	};

	const closeItems = (): void => {
		AcceptanceService.postSpacesClose()
			.then(() => {
				closeConfirm();
				close();
				playAudio('success');
			})
			.catch((err) => {
				playAudio('error');
				notice.setNotice({ message: err?.error || err?.message || '' });
			})
			.finally(() => setLoading(false));
	};

	const submit = (): void => {
		if (emall.modalItems?.hasUnscanned) {
			setShowConfirm(true);
		} else {
			closeItems();
		}
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (window.Echo && operator?.id && emall.modalItems && !showConfirm) {
			listenChannel(
				({ number }) => {
					emall.modalItems &&
						emall.modalItems?.scanBarcode(number).catch((err) => console.log('emall scanBarcode err:', err));
				},
				'.routing.number',
				operator.id
			);
		}

		return leave;
	}, [!!emall.modalItems, showConfirm]);

	return (
		<>
			<Modal
				title="Приемка пакетов Emall"
				okText="Завершить приемку"
				cancelText="Закрыть"
				open={!!emall.modalItems}
				width={720}
				onCancel={close}
				onOk={submit}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
				okButtonProps={{ disabled: !emall.modalItems?.hasItems }}
			>
				<Header />
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<ItemsTable />
				</OverlayScrollbarsComponent>
			</Modal>
			<Modal
				classNameModal={styles.confirm}
				className={styles.confirm__body}
				title={null}
				okText="Досканировать"
				cancelText="Принять с расхождением"
				open={showConfirm}
				onCancel={closeItems}
				onOk={closeConfirm}
				hasLines={false}
				centered
				width={430}
				closable={false}
			>
				<h3 className={styles.confirm__title}>Отсканированы не все места из списка</h3>
				<p className={styles.confirm__text}>Досканируйте или примите с расхождением</p>
			</Modal>
			<Modal
				classNameModal={styles.info}
				className={styles.info__body}
				title={null}
				open={!!emall.modalItems?.infoModal}
				onCancel={emall.modalItems?.closeInfoModal}
				hasLines={false}
				centered
				width={500}
				closable={false}
				footer={null}
			>
				<Icon className={styles.info__icon} imageName="Info" color={colors.grey2} fontSize={72} />
				<h3 className={styles.info__title}>
					Привязка пакета {emall?.modalItems?.infoModal?.routing_item?.name} заказа №
					{emall?.modalItems?.infoModal?.routing_item?.order_market_id}{' '}
					{emall?.modalItems?.infoModal?.store_place_item_id
						? `к ячейке ${emall?.modalItems?.infoModal?.store_place_item_full_name}`
						: `к новой ячейке`}
				</h3>
				{!emall?.modalItems?.infoModal?.store_place_item_id ? (
					<p className={styles.info__warn}>
						Необходимо отсканировать доступную статичную ячейку. При дальнейшем сканировании товаров данного заказа
						целевая ячейка будет предлагаться автоматически.
					</p>
				) : null}
				<Button className={styles.info__button} onClick={emall?.modalItems?.closeInfoModal}>
					Закрыть
				</Button>
			</Modal>
		</>
	);
};

export default observer(ItemsModal);
