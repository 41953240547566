import InputNumber from '@components/inputNumber';
import Popover from '@components/popover';
import Content from '@components/popover/components/content';
import RadioButton from '@components/radio-button';
import Tabs from '@components/tabs';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import ModalQR from '@pages/main/acceptance/components/header/components/modalQR';
import ModalUnidentifiedCargo from '@pages/main/acceptance/components/postal/components/cargo-spaces/components/modal-unidentified-cargo';
import { Button, Checkbox, RadioChangeEvent } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import ModalUnidentifiedPostal from 'pages/main/acceptance/components/postal/components/cargo-spaces/components/modal-unidentified-postal';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import PrintMenu from '../print-menu';
import styles from './index.module.scss';

const AcceptanceHeader: FC = () => {
	const [isShowModalPostal, setIsShowModalPostal] = useState<boolean>(false);
	const [isShowModalQr, setIsShowModalQr] = useState<boolean>(false);
	const location = useLocation();
	const navigate = useNavigate();
	const path = location.pathname.split('/')[2];

	const {
		acceptance: { emall, routeSheet, headerInfo, loadSpaces, postal },
	} = useStore();

	const [driverId, setDriverId] = useState<number | null>(null);

	const handleDriverKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
		if (evt.key === 'Enter') {
			driverId && routeSheet.loadInfoByDriver({ id: driverId, isMercenary: 0 });
			setDriverId(null);
		}
	};

	const handleRadioChange = (evt: RadioChangeEvent): void => {
		emall.setTab(evt.target.value);
	};

	return (
		<>
			<div className={styles.header}>
				<h4 className={styles.title}>Приемка</h4>
				<Button
					className={styles.qr__button}
					icon={<Icon fontSize={16} imageName="QrIcon" />}
					onClick={() => {
						setIsShowModalQr(true);
					}}
				/>
			</div>
			<div className={styles.content}>
				<Tabs
					items={[
						{
							key: 'route-sheet',
							label: <BadgedTab name="Маршрутный лист" amount={headerInfo.count_routing_lists} />,
						},
						{ key: 'postal', label: <span className={cn({ [styles.tab__new]: headerInfo.has_postal })}>Почта</span> },
						{ key: 'emall', label: <span className={cn({ [styles.tab__new]: headerInfo.has_emall })}>Emall</span> },
						// { key: 'money-bag', label: <span className={cn({ [styles.tab__new]: headerInfo.has_money })}>Денежный мешок</span> },
					]}
					activeKey={path}
					onChange={(key) => navigate(key)}
					className={styles.tabs}
				/>
				{path === 'emall' && (
					<>
						<Button type="primary" onClick={emall.loadItems}>
							Начать приёмку
						</Button>
						{emall.tab === 'product' && <PrintMenu />}
					</>
				)}
			</div>
			{path === 'postal' && (
				<div className={styles.actions}>
					<RadioButton
						className={styles.tab__radio_button}
						options={[
							{
								label: <BadgedTab name="Грузоместа" amount={headerInfo.postal_spaces || 0} />,
								value: 'cargo-spaces',
							},
							{
								label: <BadgedTab name="Проблемные посылки" amount={headerInfo.postal_trouble_items || 0} />,
								value: 'problematic-parcels',
							},
						]}
						defaultValue={postal.tab}
						onChange={(e) => postal.setTab(e.target.value)}
					/>
					{postal.tab === 'cargo-spaces' && (
						<div className={styles.buttons}>
							<Button onClick={() => postal.postalCargoSpaces.setIsOpenModalItems(true)} type="primary">
								Начать приёмку
							</Button>
							<Popover
								arrow={false}
								content={
									<Content
										items={[
											{ text: 'Посылка', onClick: () => setIsShowModalPostal(true) },
											{ text: 'Грузоместо', onClick: () => postal.setIsShowModalUnidentifiedCargo(true) },
										]}
									/>
								}
							>
								<Button className={styles.button__unidentified_cargo} icon={<Icon stroke="#1C1C1E" imageName="Plus" />}>
									Неопознанный груз
								</Button>
							</Popover>
						</div>
					)}
				</div>
			)}
			{path === 'route-sheet' && (
				<div className={styles.actions}>
					<InputNumber
						className={styles.input}
						value={driverId}
						setValue={setDriverId}
						placeholder="Введите ID водителя"
						onKeyUp={handleDriverKeyUp}
					/>
					<Checkbox
						className={styles.checkbox}
						checked={!!routeSheet.showOld}
						onChange={(evt) => {
							routeSheet.setShowOld(evt.target.checked);
							routeSheet.loadList();
						}}
					>
						Показать старые
					</Checkbox>

					<Button
						type={routeSheet.hasEntered ? 'primary' : 'default'}
						onClick={() => {
							if (routeSheet.hasEntered) {
								loadSpaces();
								routeSheet.setIsModalInfoCargoSpace(true);
							} else {
								routeSheet.setIsOpenModalContinueAcceptance(true);
								routeSheet.setIsModalInfoCargoSpace(true);
							}
						}}
					>
						{routeSheet.hasEntered ? 'Начать приёмку' : 'Продолжить приёмку'}
					</Button>
				</div>
			)}
			{path === 'emall' && (
				<div className={styles.actions}>
					<RadioButton
						options={[
							emall.tab === 'bag'
								? { label: 'Пакетная сборка', value: 'bag' }
								: { label: 'Потоварная сборка', value: 'product' },
						]}
						value={emall.tab}
						onChange={handleRadioChange}
					/>
				</div>
			)}
			<ModalUnidentifiedPostal isShow={isShowModalPostal} setIsShow={setIsShowModalPostal} />
			<ModalUnidentifiedCargo />
			<ModalQR isShow={isShowModalQr} setIsShow={setIsShowModalQr} />
		</>
	);
};

export default observer(AcceptanceHeader);

const BadgedTab: FC<{ name: string; amount: number; className?: string }> = ({ name, amount }) => {
	return (
		<div className={styles.tab}>
			<span>{name}</span>
			<span className={styles.tab__badge}>{amount}</span>
		</div>
	);
};
