/* eslint-disable @typescript-eslint/no-empty-interface */
import { ImageName } from '@images/index';
import { AcceptanceService } from '@services/acceptance';
import PostalCargoSpaces from '@store/acceptance/postal/cargo-spaces';
import PostalTrouble from '@store/acceptance/postal/postal-trouble';
import { cast, flow, getRoot, getSnapshot, types } from 'mobx-state-tree';

import { voiceMessage } from '../../../utils/voiceMessage';
import { RootStore } from '../../index';

const { optional, boolean, number, union, literal, model, maybeNull, array, string } = types;

const ModelGetSpacesUnidentified = model('get-spaces-unidentified', {
	id: maybeNull(number),
	number: maybeNull(string),
	title: string,
	description: string,
	status: union(literal(0), literal(1), literal(2), literal(3), literal(4)),
});

const Postal = model('postal', {
	postalCargoSpaces: PostalCargoSpaces,
	postalTrouble: PostalTrouble,
	isShowModalUnidentifiedCargo: optional(boolean, false),
	unidentifiedCargoNumbers: maybeNull(array(string)),
	responseGetSpacesUnidentified: maybeNull(ModelGetSpacesUnidentified),
	tab: optional(union(literal('cargo-spaces'), literal('problematic-parcels')), 'cargo-spaces'),
})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);
		let timerId: any;

		return {
			setTab(tab: 'cargo-spaces' | 'problematic-parcels') {
				self.tab = tab;
			},
			getSpacesUnidentified: flow(function* (value: string[]) {
				root.setLoading(true);
				clearTimeout(timerId);
				try {
					const res = yield AcceptanceService.getSpacesUnidentified({ numbers: value });

					self.responseGetSpacesUnidentified = res.data;

					if (self.responseGetSpacesUnidentified?.status === 0) {
						timerId = setTimeout(() => root.acceptance.postal.closeModalConfirmSpacesUnidentified(), 5000);
						voiceMessage({ message: self.responseGetSpacesUnidentified?.title });
					}
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			scanBarcode: flow(function* (value: string): Generator<any, void, any> {
				if (self.isShowModalUnidentifiedCargo && !self.unidentifiedCargoNumbers?.includes(value)) {
					const currentArray = self.unidentifiedCargoNumbers ? getSnapshot(self.unidentifiedCargoNumbers) : [];
					const newArray = Array.isArray(currentArray) ? [...currentArray, value] : [value];
					self.unidentifiedCargoNumbers = cast(newArray);

					if (self.unidentifiedCargoNumbers && self.unidentifiedCargoNumbers?.length >= 2) {
						root.acceptance.postal.getSpacesUnidentified(self.unidentifiedCargoNumbers).catch((err: any) => {
							console.log('getSpacesUnidentified', err);
						});
					}
				}
				if (root.acceptance.postal.postalCargoSpaces.isOpenModalItems) {
					yield root.acceptance.postal.postalCargoSpaces.loadItemsForScan(value);
				}
			}),
			closeModalConfirmSpacesUnidentified() {
				self.responseGetSpacesUnidentified = null;
			},
			setIsShowModalUnidentifiedCargo(value: boolean) {
				if (!value) {
					self.unidentifiedCargoNumbers = null;
				}
				self.isShowModalUnidentifiedCargo = value;
			},
			handleScanner(evt: KeyboardEvent) {
				root.scanner.handleScanner(evt, (value) => {
					if (value === 'REPEATVOICEMESSAGE') {
						voiceMessage();
						return;
					}
					root.acceptance.postal.scanBarcode(value);
				});
			},
		};
	})
	.views(() => ({
		getIconName(type: 0 | 1 | 2 | 3 | 4): ImageName {
			switch (type) {
				case 0:
					return 'SearchPlus';
				case 1:
					return 'CheckCircle';
				case 2:
					return 'SearchPlus';
				default:
					return 'Info';
			}
		},
	}));

export default Postal;
