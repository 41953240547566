import { useStore } from '@hooks/useStore';
import { IBagsCollectModel } from '@store/visits/order-collect-bags';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ItemsTable: FC = () => {
	const { tasks } = useStore();

	const columns: ColumnsType<IBagsCollectModel> = [
		{
			title: <span>Пакет</span>,
			dataIndex: 'bag',
			className: styles.cell,
			render: (_, { name }) => <div>{name}</div>,
		},
		{
			title: <span>Ячейка</span>,
			dataIndex: 'cell',
			className: styles.cell,
			render: (_, { store_place_full_name }) => <span>{store_place_full_name || ''}</span>,
		},
	];

	return (
		<Table
			pagination={false}
			columns={columns}
			dataSource={tasks?.ordersCollectBags?.bags?.map((item) => ({ ...item, key: item.id }))}
			onRow={({ is_collected }) => ({
				className: cn(styles.row, {
					[styles.row__check]: is_collected,
				}),
			})}
			onHeaderRow={() => ({ className: styles.row })}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
		/>
	);
};

export default observer(ItemsTable);
