import { SnapshotIn, types } from 'mobx-state-tree';
const { model, number, string, array, union, literal } = types;

const OrderCollectItem = model('order-collect-item', {
	product_id: number,
	name: string,
	store_place_item_id: number,
	store_place_full_name: string,
	quantity: number,
	collected_count: number,
	price: number,
	final_price: number,
	key: '',
});

const OrdersCollect = model('order-collect', {
	order_ids: array(number),
	receiver_fio: string,
	count: number,
	collected_count: number,
	items: array(OrderCollectItem),
	total_price: number,
});

export default OrdersCollect;

export type IOrdersCollect = SnapshotIn<typeof OrdersCollect>;
export type IOrderCollectItem = SnapshotIn<typeof OrderCollectItem>;
