import Button from '@components/buttons/button';
import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import styles from './index.module.scss';

const Header: FC = () => {
	const {
		shipment: {
			emall: {
				consolidation: { modalGoodsTable, getScanned, getSpaceLength },
			},
		},
	} = useStore();

	const [barcodeModal, setBarcodeModal] = useState(false);
	const [barcode, setBarcode] = useState<string>('');
	const [barcodeError, setBarcodeError] = useState('');

	const closeBarcodeModal = (): void => {
		setBarcodeModal(false);
		setBarcode('');
		setBarcodeError('');
	};

	const handleBarcodeChange = (value: string): void => {
		setBarcodeError('');
		setBarcode(value);
	};

	return (
		<>
			<header className={styles.header}>
				<Button className={styles.header__button} type="text" onClick={() => setBarcodeModal(true)}>
					Ввести штрихкод вручную
				</Button>
				<span className={styles.counter}>
					<span className={styles.counter__current}>
						{getScanned()}
						{modalGoodsTable?.space?.surplusCount ? <span>+{modalGoodsTable.space.surplusCount}</span> : null}
					</span>
					<span>/{getSpaceLength()}</span>
				</span>
			</header>
			<Modal
				classNameModal={styles.modal}
				className={styles.modal__body}
				title={null}
				okText="Добавить"
				cancelButtonProps={{ style: { display: 'none' } }}
				open={barcodeModal}
				onCancel={closeBarcodeModal}
				onOk={() => {
					if (!barcode) return;

					modalGoodsTable
						?.scanBarcode(barcode)
						.then(closeBarcodeModal)
						.catch((err) => setBarcodeError(err));
				}}
				hasLines={false}
				centered
				width={416}
				closable={false}
				destroyOnClose
				okButtonProps={{ disabled: !!barcodeError || !barcode }}
			>
				<h3 className={styles.modal__title}>Введите штрихкод товара</h3>
				<Input
					isNumber
					className={styles.input}
					value={barcode}
					onChange={(evt) => handleBarcodeChange(evt.target.value)}
				/>
				{barcodeError ? <p className={styles.error}>{barcodeError}</p> : null}
			</Modal>
		</>
	);
};

export default observer(Header);
