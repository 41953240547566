import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Header from '@pages/main/tasks/components/emall/components/modal-order-collect-bags/components/header';
import ItemsTable from '@pages/main/tasks/components/emall/components/modal-order-collect-bags/components/table';
import OrderService from '@services/order';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { playAudio } from '../../../../../../../utils/playSound';
import styles from './index.module.scss';

const ModalOrderCollectBags = () => {
	const store = useStore();

	const closeCollect = async (): Promise<void> => {
		store.setLoading(true);

		try {
			await OrderService.postCancelOrderCollect({ order_ids: store?.tasks?.ordersCollectBags?.order_ids || [] });
			store?.tasks?.closeModalOrderCollectBags();
			playAudio('posted');
		} catch (err: any) {
			playAudio('error');
			store.notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			store.setLoading(false);
		}
	};

	return (
		<Modal
			title={
				<div className={styles.title}>
					{store?.tasks?.ordersCollectBags?.bags.length !== 1
						? 'Все заказы клиента'
						: `Заказ №${store?.tasks?.ordersCollectBags?.bags[0]?.order_market_id}`}
				</div>
			}
			open={!!store?.tasks?.ordersCollectBags}
			width={720}
			footer={
				<div className={styles.footer}>
					<Button
						onClick={() =>
							store.print.setPrintPreview({
								type: 'bags-for-collect',
								ids: store?.tasks?.ordersCollectBags?.order_ids.map((el) => el) || [],
							})
						}
					>
						Печать списка товаров
					</Button>
					<Button onClick={closeCollect} type="primary">
						Завершить сборку
					</Button>
				</div>
			}
			onCancel={store?.tasks?.closeModalOrderCollectBags}
			className={styles.body}
			classNameModal={styles.modal}
			centered
			destroyOnClose
		>
			<Header />
			<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
				<ItemsTable />
			</OverlayScrollbarsComponent>
		</Modal>
	);
};

export default observer(ModalOrderCollectBags);
