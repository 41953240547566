import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Header: FC = () => {
	const visit = useCurrentVisit();

	return (
		<>
			<header className={styles.header}>
				<span className={styles.receiver}>{visit && visit.ordersCollectBags?.receiver_fio}</span>
				<span className={styles.counter}>
					<span className={styles.counter__current}>{(visit && visit.countCollectedOrdersBags) || 0}</span>
					<span>/{(visit && visit.ordersCollectBags?.count) || 0}</span>
				</span>
			</header>
		</>
	);
};

export default observer(Header);
