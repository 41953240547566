/* eslint-disable @typescript-eslint/no-empty-interface */
import { types } from 'mobx-state-tree';
import { getCharByKeyCode } from 'utils/getCharByKeyCode';
import { voiceMessage } from 'utils/voiceMessage';

const { model } = types;

const Scanner = model('scanner', {
	value: '',
}).actions((self) => {
	let timerId: any;

	return {
		clear() {
			self.value = '';
		},
		handleScanner({ key, keyCode, shiftKey, target }: KeyboardEvent, action: (number: string) => void) {
			//@ts-ignore
			if (target.tagName === 'INPUT' && !target.dataset.canScanner) return;

			if (self.value === 'NEWCELL') {
				voiceMessage({ message: 'Выберите ячейку' });
			}

			clearTimeout(timerId);
			timerId = setTimeout(this.clear, 150);
			self.value += getCharByKeyCode(keyCode, shiftKey).trim();

			if (key === 'Enter' && self.value) {
				let data: any;

				try {
					data = JSON.parse(self.value);
				} catch {
					data = self.value;
				}

				action(typeof data === 'object' ? self.value : self.value.toUpperCase());
				this.clear();
			}
		},
	};
});

export default Scanner;
