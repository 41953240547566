export const replaceNumber = (number: string): string => {
	const regex = /[^0-9.]/g;
	let result = number.replace(regex, '');
	const decimalCount = (result.match(/\./g) || []).length;
	if (decimalCount > 1) {
		const firstIndex = result.indexOf('.');
		result = result.slice(0, firstIndex + 1) + result.slice(firstIndex + 1).replace(/\./g, '');
	}
	return result;
};
