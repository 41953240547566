import PdfModal from '@components/pdf-modal';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import cn from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

import AcceptanceHeader from './components/header';
import styles from './index.module.scss';

let timerId: any;

const Acceptance: FC = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [noShadow, setNoShadow] = useState(true);

	return (
		<Layout>
			<Header className={cn(styles.header, { [styles.header__no_shadow]: noShadow })}>
				<AcceptanceHeader />
			</Header>
			<Content>
				<OverlayScrollbarsComponent
					className={styles.scroll}
					options={{ scrollbars: { autoHide: 'move' } }}
					events={{
						scroll: () => setNoShadow(!ref.current?.parentElement?.scrollTop),
					}}
				>
					<div className={styles.scroll__wrap} ref={ref}>
						<Outlet />
					</div>
				</OverlayScrollbarsComponent>
			</Content>
			<PdfModal />
		</Layout>
	);
};

export default Acceptance;
