/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import { ShipmentService } from '@services/shipment';
import { cast, destroy, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';
import { removeMaskEmall } from 'utils/removeMaskEmall';
import { sortRYG } from 'utils/sortRYG';
import { voiceMessage } from 'utils/voiceMessage';

import { playAudio } from '../../utils/playSound';
import { RootStore } from '..';

const { string, optional, boolean, number, union, literal, model, maybeNull, array } = types;

const Order = model('order', {
	id: number,
	order_market_id: number,
	order_status: number,
	receiver_fio: string,
	product_count: number,
	bags: maybeNull(
		array(
			model('bags', {
				name: string,
				is_scanned: optional(union(literal(0), literal(1)), 0),
			})
		)
	),
});

const Item = model('items', {
	id: number,
	name: string,
	order_market_id: number,
	store_place_item_full_name: maybeNull(string),
	is_scanned: optional(union(literal(0), literal(1)), 0),
	isSurplus: maybeNull(boolean),
	isNewError: maybeNull(boolean),
	message: maybeNull(string),
	key: maybeNull(number),
}).actions((self) => ({
	update(postal: Partial<IItem>) {
		Object.keys(postal).forEach((key) => {
			if (key in self) {
				//@ts-ignore
				self[key] = postal[key];
			}
		});
	},
}));

const ScanningPackage = model('scanning-package', {
	store_place_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	store_place_item_text: maybeNull(string),
	scanning_status: union(literal(1), literal(2), literal(2), literal(4), literal(5)),
	scanning_message: string,
	order_bag: model('order_bag', {
		id: number,
		name: string,
		order_market_id: number,
	}),
	routing_item: maybeNull(Item),
});

const ItemsModal = model('modal-items', {
	routing_items: maybeNull(array(Item)),
	scanned_count: number,
	count: number,
	infoModal: maybeNull(ScanningPackage),
	isNewCell: optional(boolean, false),
})
	.actions((self) => {
		return {
			scanPlaceItemBarcode: flow(function* (cell: string) {
				if (!self.infoModal || !self.infoModal.routing_item?.id) return;
				const root = getRoot<typeof RootStore>(self);

				if (
					self.infoModal.store_place_item_id &&
					self.infoModal.store_place_item_id.toString() !== cell.replace(/^0+/, '') &&
					!self.isNewCell
				) {
					playAudio('error');
					voiceMessage({ message: 'Неверная ячейка', isSave: true });
					root.notice.setNotice({ message: 'Неверная ячейка' });

					return;
				}

				root.setLoading(true);

				const placeId: number | null = self.infoModal.store_place_item_id
					? self.isNewCell
						? self.infoModal.store_place_item_id
						: null
					: null;

				try {
					const { data } = yield AcceptanceService.postEmallBagsPlaceItemScan({
						store_place_item_id_to_block: placeId,
						store_place_item_id_to_choose: Number(cell),
						routing_item_id: self.infoModal.routing_item?.id,
					});

					const item = self.routing_items?.find((el) => el?.name === self.infoModal?.routing_item?.name);

					item
						? item.update({
								...item,
								store_place_item_full_name: data?.store_place_item_full_name,
								is_scanned: self.infoModal?.scanning_status === 1 ? self.infoModal?.scanning_status : 0,
								isNewError: false,
								message: self.infoModal?.scanning_message,
								isSurplus: self.infoModal?.scanning_status === 2,
						  })
						: self.routing_items?.push({
								...self.infoModal?.routing_item,
								store_place_item_full_name: data?.store_place_item_full_name,
								is_scanned: self.infoModal?.scanning_status === 1 ? self.infoModal?.scanning_status : 0,
								isNewError: false,
								message: self.infoModal?.scanning_message,
								isSurplus: self.infoModal?.scanning_status === 2,
						  });

					playAudio((self.infoModal?.scanning_status === 2 && 'warning') || 'posted');

					self.infoModal = null;
					self.isNewCell = false;
				} catch (err: any) {
					playAudio('error');
					voiceMessage({ message: err?.message, isSave: true });
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			scanBarcode: flow(function* (barcode: string) {
				const root = getRoot<typeof RootStore>(self);

				if (!self.routing_items) {
					root.notice.setNotice({ message: 'Нет пакетов' });
					return;
				}

				root.setLoading(true);

				try {
					const { data } = yield AcceptanceService.postEmallPackageScan(barcode);

					if (self.routing_items?.some((el) => el.name === barcode && el.isNewError)) {
						voiceMessage({ message: 'Пакет уже отсканирован, с ошибкой', isSave: true });
						playAudio('error');
						root.notice.setNotice({ message: 'Пакет уже отсканирован, с ошибкой' });
						return;
					}

					if ([1, 2].includes(data.scanning_status)) {
						voiceMessage({ message: data.store_place_item_text, isSave: true });
						playAudio('success');
						self.infoModal = data;
						return;
					}

					if (data?.scanning_status !== 5) {
						const item = self.routing_items?.find((el) => el?.name === data.order_bag.name);

						item
							? item.update({
									...item,
									is_scanned: 0,
									isNewError: true,
									message: data?.scanning_message,
									isSurplus: false,
							  })
							: self.routing_items?.push({
									name: data?.order_bag?.name,
									order_market_id: data?.order_bag?.order_market_id,
									id: data?.order_bag?.id,
									is_scanned: 0,
									isNewError: true,
									message: data?.scanning_message,
									isSurplus: false,
							  });
					}

					voiceMessage({ message: (data.scanning_status === 5 && data.scanning_message) || 'Ошибка', isSave: true });

					playAudio(
						(data.scanning_status === 2 && 'warning') ||
							([3, 4, 5].includes(data.scanning_status) && 'error') ||
							'posted'
					);

					root.notice.setNotice({ message: data.scanning_message });
				} catch (err: any) {
					playAudio('error');
					root.notice.setNotice({ message: err?.error || err?.message || '' });
					throw err?.error || err?.message || 'Ошибка';
				} finally {
					root.setLoading(false);
				}
			}),
			deleteItem: flow(function* (item: IItem) {
				const root = getRoot<typeof RootStore>(self);

				if (!self.routing_items) {
					root.notice.setNotice({ message: 'Нет пакетов' });
					return;
				}

				root.setLoading(true);

				try {
					if (item.isNewError) {
						self.routing_items = cast(self.routing_items.filter(({ id }) => id !== item.id));
					} else {
						yield ShipmentService.putItems(item.id, { is_scanned: 0 });
						root.acceptance.emall.loadItems();
					}
					playAudio('success');
				} catch (err: any) {
					playAudio('error');
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			closeInfoModal() {
				self.infoModal = null;
			},
			setIsNewCell(value: boolean) {
				self.isNewCell = value;
			},
		};
	})
	.views((self) => ({
		get scannedCount() {
			return self.routing_items?.filter(({ is_scanned: isScan }) => isScan).length;
		},
		get totalItems() {
			return self.routing_items?.filter(({ isNewError, isSurplus }) => !isSurplus || !isNewError).length;
		},
		get surplusCount() {
			return self.routing_items?.filter(({ isSurplus }) => isSurplus).length;
		},
		get errorCount() {
			return self.routing_items?.filter(({ isNewError }) => isNewError).length;
		},
		get hasItems() {
			return self.routing_items && self.routing_items?.length > 0;
		},
		get hasUnscanned() {
			return self.routing_items?.some(({ is_scanned: isScan, isNewError }) => !isScan && !isNewError);
		},
		get sortItems() {
			return self.routing_items?.slice().sort((a, b): -1 | 0 | 1 =>
				sortRYG([
					[!!a.isNewError, !!b.isNewError],
					[!!a.isSurplus, !!b.isSurplus],
					[!!a.is_scanned, !!b.is_scanned],
				])
			);
		},
	}));

const Product = model('product', {
	id: number,
	item_market_id: number,
	name: string,
	order_market_id: maybeNull(number),
	order_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	isSurplus: false,
});

const ProductInfoModal = model('modal-info', {
	show: false,
	store_place_item_id: maybeNull(number),
	store_place_item_full_name: maybeNull(string),
	store_place_item_text: maybeNull(string),
	item: Product,
});

const ItemsProductModal = model('modal-items-product', {
	items: maybeNull(array(Product)),
	description: '',
	spaceId: maybeNull(number),
	type: maybeNull(union(literal(1), literal(2))),
	infoModal: maybeNull(ProductInfoModal),
	isNewCell: false,
})
	.actions((self) => {
		return {
			setIsNewCell(value: boolean) {
				self.isNewCell = value;
			},
			scanPlaceItemBarcode: flow(function* (barcode: number) {
				if (!self.infoModal || !self.infoModal.item.order_item_id) return;
				if (self.infoModal.store_place_item_id && self.infoModal.store_place_item_id !== barcode && !self.isNewCell) {
					playAudio('error');
					voiceMessage({ message: 'Неверная ячейка', isSave: true });
					return;
				}

				const root = getRoot<typeof RootStore>(self);
				root.setLoading(true);

				const placeId: number | null = self.infoModal.store_place_item_id
					? self.isNewCell
						? self.infoModal.store_place_item_id
						: null
					: null;

				try {
					yield AcceptanceService.postEmallPlaceItemScan({
						store_place_item_id_to_block: placeId,
						store_place_item_id_to_choose: barcode,
						order_item_id: self.infoModal.item.order_item_id,
						order_market_id: self.infoModal.item.order_market_id,
					});

					self.isNewCell = false;
					root.acceptance.emall.modalItemsProduct?.closeInfoModal();
					playAudio('posted');
				} catch (err: any) {
					playAudio('error');
					voiceMessage({ message: err?.message, isSave: true });
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			scanItemBarcode: flow(function* (barcode: string) {
				const root = getRoot<typeof RootStore>(self);
				root.setLoading(true);

				try {
					if (!self.spaceId) throw 'Нет идентификатора грузоместа';

					const {
						data: { scanning_status: status, ...data },
					} = yield AcceptanceService.postEmallItemScan({ barcode, routing_space_id: self.spaceId });

					status === 1 && (self.infoModal = ProductInfoModal.create({ show: true, ...data }));

					if (status === 2) {
						const item: IProduct = {
							...data.item,
							store_place_item_full_name: data.store_place_item_full_name,
							isSurplus: true,
						};

						self.items = cast(self.items ? [item, ...self.items] : [item]);
					}
					playAudio(status === 1 ? 'success' : 'warning');
					status === 1 && voiceMessage({ message: data.store_place_item_text || '', isSave: true });
				} catch (err: any) {
					playAudio('error');
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			scanBarcode: flow(function* (barcode: string) {
				const root = getRoot<typeof RootStore>(self);
				const isBox = barcode.length === 11 && barcode.startsWith('1') && barcode.slice(-1) === '5';

				if (self.type === 1 && !isBox) {
					root.acceptance.emall.modalItemsProduct?.scanItemBarcode(barcode);
					return;
				}

				root.setLoading(true);

				try {
					const {
						data: { type, scanning_status: status, ...data },
					} = yield AcceptanceService.postEmallScan(+removeMaskEmall(barcode));

					self.type = type || null;

					if (type === 1 || isBox) {
						self.description = `Ящик ${data.space.name}`;
						self.items = null;
						self.spaceId = data.space.id;
						playAudio('success');
					}

					if (type === 2) {
						status === 1 && (self.infoModal = ProductInfoModal.create({ show: true, ...data }));

						if (status === 2) {
							const item = { ...data.item, isSurplus: true };
							self.items = cast(self.items ? [item, ...self.items] : [item]);
						}
						playAudio(status === 2 ? 'warning' : 'success');
						voiceMessage({ message: status === 1 ? data.store_place_item_text : 'Излишек', isSave: true });
					}
				} catch (err: any) {
					playAudio('error');
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			closeInfoModal() {
				const root = getRoot<typeof RootStore>(self);
				root.acceptance.emall.loadItems();
				self.infoModal = null;
			},
			confirm: flow(function* () {
				const root = getRoot<typeof RootStore>(self);

				root.setLoading(true);

				try {
					yield AcceptanceService.postSpacesCloseByProduct(
						self.spaceId ? { space_id: self.spaceId } : { item_ids: self.items?.map(({ id }) => id) }
					);
					playAudio('success');
					root.acceptance.emall.closeModalItems();
				} catch (err: any) {
					playAudio('error');
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
		};
	})
	.views((self) => ({
		get hasItems() {
			return self.items && self.items.length > 0;
		},
	}));

const Emall = model('emall', {
	tab: '',
	orders: maybeNull(array(Order)),
	modalItems: maybeNull(ItemsModal),
	modalItemsProduct: maybeNull(ItemsProductModal),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);
	return {
		setTab(tab: 'bag' | 'product') {
			self.tab = tab;
		},
		loadOrders: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield AcceptanceService.getEmallOrders();
				self.orders = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadItems: flow(function* () {
			root.setLoading(true);
			try {
				if (self.tab === 'product') {
					const { data } = yield AcceptanceService.getEmallItemsProduct();
					if (self.modalItemsProduct) return (self.modalItemsProduct.items = data);
					self.modalItemsProduct = ItemsProductModal.create({ items: data });
				} else {
					const res = yield AcceptanceService.getEmallItems();
					const addedItems = self.modalItems?.routing_items?.filter(({ isNewError }) => isNewError) || [];
					const items = [...addedItems, ...res.data.routing_items];

					self.modalItems = self.modalItems ? { ...res.data, routing_items: cast(items) } : ItemsModal.create(res.data);
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		closeModalItems() {
			self.modalItems = null;
			self.modalItemsProduct = null;
			this.loadOrders();
		},
		hideOrder(order: IOrder) {
			destroy(order);
		},
		handleScanner(evt: KeyboardEvent) {
			root.scanner.handleScanner(evt, (value) => {
				if (value === 'REPEATVOICEMESSAGE') return voiceMessage();
				if (value === 'NEWCELL') {
					self.modalItems && self.modalItems?.setIsNewCell(true);
					self.modalItemsProduct && self.modalItemsProduct?.setIsNewCell(true);
					voiceMessage({ message: 'Выберите ячейку' });
					return;
				}
				if (self.modalItems?.infoModal) {
					self.modalItems?.scanPlaceItemBarcode(value);
					return;
				}

				if (self.modalItemsProduct?.infoModal?.show) {
					self.modalItemsProduct?.scanPlaceItemBarcode(+value);
					return;
				}

				self.modalItems?.scanBarcode(removeMaskEmall(value)).catch((err) => {
					playAudio('error');
					console.log('emall-bag/scanner scanBarcode err:', err);
				});

				self.modalItemsProduct?.scanBarcode(value).catch((err) => {
					playAudio('error');
					console.log('emall-product/scanner scanBarcode err:', err);
				});
			});
		},
	};
});

export default Emall;

export interface IItemsModal extends SnapshotIn<typeof ItemsModal> {}
export interface IItem extends SnapshotIn<typeof Item> {}
export interface IOrder extends SnapshotIn<typeof Order> {}
export interface IProduct extends SnapshotIn<typeof Product> {}
export interface IScanningPackage extends SnapshotIn<typeof ScanningPackage> {}
