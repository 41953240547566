import Input from '@components/input';
import InputNumber from '@components/inputNumber';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const OtherPayments: FC = () => {
	const { kassa } = useStore();
	return (
		<div className={styles.other_payments}>
			<div style={{ display: 'flex', gap: 16, paddingBottom: 8, fontWeight: 500 }}>
				<div style={{ flex: 1 }}></div>
				<div style={{ flex: 1 }}>Почта</div>
				<div style={{ flex: 1 }}>Заказы</div>
				<div style={{ flex: 1 }}>Итого</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 16, color: colors.grey1 }}>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Возврат наличными</div>
					<div style={{ flex: 1 }}>
						<Input
							style={{ height: 38, width: '100%' }}
							isNumber
							value={kassa.reportFull?.cash_post_return?.toString() || ''}
							onChange={(e) => kassa.reportFull?.setCashPostReturn(+e.target.value)}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<Input
							style={{ height: 38, width: '100%' }}
							isNumber
							value={kassa.reportFull?.cash_order_return?.toString() || ''}
							onChange={(e) => kassa.reportFull?.setCashOrderReturn(+e.target.value)}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={{ height: 38, width: '100%' }}
							value={kassa.reportFull?.totalCashPostAndOrderReturn || 0}
							disabled
						/>
					</div>
				</div>
				<div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
					<div style={{ flex: 1 }}>Возврат на карту</div>
					<div style={{ flex: 1 }}>
						<Input
							style={{ height: 38, width: '100%' }}
							isNumber
							value={kassa.reportFull?.card_post_return?.toString() || ''}
							onChange={(e) => kassa.reportFull?.setCardPostReturn(+e.target.value)}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<Input
							style={{ height: 38, width: '100%' }}
							isNumber
							value={kassa.reportFull?.card_order_return?.toString() || ''}
							onChange={(e) => kassa.reportFull?.setCardOrderReturn(+e.target.value)}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}>
						<InputNumber
							style={{ height: 38, width: '100%' }}
							value={kassa.reportFull?.totalCardPostAndOrderReturn || 0}
							disabled
						/>
					</div>
				</div>

				<div style={{ display: 'flex', gap: 16, alignItems: 'flex-start' }}>
					<div style={{ flex: 1, lineHeight: '20px' }}>Компенсация наличными</div>
					<div style={{ flex: 1 }}>
						<Input
							style={{ height: 38, width: '100%' }}
							isNumber
							value={kassa.reportFull?.cash_post_compensation?.toString() || ''}
							onChange={(e) => kassa.reportFull?.setCashPostCompensation(+e.target.value)}
							disabled={kassa.reportFull?.readonly}
						/>
					</div>
					<div style={{ flex: 1 }}></div>
					<div style={{ flex: 1 }}></div>
				</div>
			</div>
		</div>
	);
};

export default observer(OtherPayments);
