import { useCurrentVisit } from '@hooks/useCurrentVisit';
import ModalConfirmTypeCollect from '@pages/main/visits/components/visit/compoments/modal-confirm-type-collect';
import ModalOrderCollect from '@pages/main/visits/components/visit/compoments/modal-order-collect';
import ModalOrderCollectBags from '@pages/main/visits/components/visit/compoments/modal-order-collect-bags';
import ModalScannedCollectParcel from '@pages/main/visits/components/visit/compoments/modal-scanned-collect-parcel';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import ConfirmModal, { ItemsType } from './compoments/confirm-modal';
import Footer from './compoments/footer';
import IncidentModal from './compoments/incident-modal';
import ModalAdultConfirm from './compoments/modal-adult-confirm';
import ModalUnionCheck from './compoments/modal-union-check';
import OrderCorrectionModal from './compoments/order-correction-modal';
import PostalProcessingModal from './compoments/postal-modal';
import PostalTable, { PostalTableDataType, PostalTableProps } from './compoments/postal-table';
import styles from './index.module.scss';
import { getTableDataFromPostalsAndOrders } from './ts/getTableData';

export interface IStateConfirmModalCollect {
	isOpen: boolean;
	id: number | null;
}

const Visit: FC = observer(() => {
	const [stateConfirmModal, setStateConfirmModal] = useState<IStateConfirmModalCollect>({
		id: null,
		isOpen: false,
	});
	const [confirmItems, setConfirmItems] = useState<ItemsType[]>([]);
	const visit = useCurrentVisit();

	const buttonClickHandler = (btnType: PostalTableDataType['buttonType'], postalId: number): void => {
		if (btnType === 'process') {
			visit?.processPostal(postalId);
		} else if (btnType === 'confirm-postal') {
			setConfirmItems([
				{
					active: true,
					name: 'Проверка комплектности',
					number: visit?.getPostal(postalId)?.number ?? '',
					id: postalId,
				},
			]);
		} else if (btnType === 'not-collected') {
			if (visit?.orders && visit?.orders.filter((el) => el.is_collected === 0).length > 1) {
				setStateConfirmModal({
					id: postalId,
					isOpen: true,
				});
			} else {
				visit?.setOrdersCollect(true, postalId);
			}
		}
	};
	const confirmCompleteness = (items: ItemsType[]): void => {
		items.forEach((item) => visit?.getPostal(item.id)?.confirmCompleteness(Number(item.active) as 0 | 1));
		setConfirmItems([]);
	};
	const actionHandler: PostalTableProps['onActionClick'] = (action, id, type) => {
		if (action === 'delete') {
			if (type !== 'order') {
				visit?.deletePostal(id);
			} else {
				visit?.deleteOrder(id);
			}
		}

		if (action === 'correction') visit?.setModalCorrectionId(id);
		if (action === 'incident') visit?.icidents?.setId(id);
		if (action === 'edit') visit?.processPostal(id);
	};
	if (visit && !visit.isEmpty) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 27,
					height: 'calc(100vh - 290px)',
					minHeight: 500,
					overflow: 'hidden',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', gap: 24, flex: 1, maxHeight: 'calc(100% - 150px)' }}>
					{!!visit.issues.length && (
						<PostalTable
							title="Выдать"
							data={getTableDataFromPostalsAndOrders(visit.issues)}
							onActionClick={actionHandler}
							onButtonClick={buttonClickHandler}
						/>
					)}
					{!!visit.receptions.length && (
						<PostalTable
							title="Принять"
							classTableWrap={styles.wrap__accept}
							data={getTableDataFromPostalsAndOrders(visit.receptions)}
							onButtonClick={buttonClickHandler}
							onActionClick={actionHandler}
							hasClearButton
						/>
					)}
				</div>
				{/* <Services className={styles.services} /> */}
				<Footer />
				<PostalProcessingModal />
				{!!confirmItems.length && (
					<ConfirmModal
						items={confirmItems}
						open={!!confirmItems.length}
						onCancel={() => setConfirmItems([])}
						onConfirm={confirmCompleteness}
					/>
				)}
				{!!visit?.modalCorrectionId && <OrderCorrectionModal />}
				{visit.icidents.id && <IncidentModal />}
				<ModalUnionCheck />
				<ModalAdultConfirm />
				<ModalScannedCollectParcel />
				<ModalConfirmTypeCollect stateConfirmModal={stateConfirmModal} setStateConfirmModal={setStateConfirmModal} />
				<ModalOrderCollect />
				<ModalOrderCollectBags />
			</div>
		);
	} else {
		visit?.clear();
		return null;
	}
});

export default Visit;
