import Echo from 'laravel-echo';
import io from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_API_URL;

declare global {
	interface Window {
		io: typeof io;
		Echo: Echo;
	}
}

window.io = io;

export const createSocketConnection = (id?: number): void => {
	const headersValue = id ? { OperatorId: id } : { OperatorOpsToken: localStorage.getItem('token') };
	window.Echo = new Echo({
		client: io,
		broadcaster: 'socket.io',
		host: URL,
		transports: ['polling', 'websocket'],
		auth: {
			headers: {
				...headersValue,
				'Content-Type': 'application/json',
			},
		},
	});
};

export const listenChannel = (callBack: (payload: any) => void, event: string, id?: number): void => {
	if (!id) return console.log('listenChannel - нет id', event);
	window.Echo.private(`web.operator.${id}`).listen(event, (payload: any) => callBack(payload));
};

export const leaveEvent = (event: string, id?: number): void => {
	if (!id) return console.log('leaveEvent - нет id', event);
	window.Echo.private(`web.operator.${id}`).stopListening(event);
};

export const listenChannelMobile = (callBack: (payload: any) => void, event: string, id?: number): void => {
	if (!id) return console.log('listenChannelMobile - нет id', event);
	window.Echo.private(`mobile-scan.operator.${id}`).listen(event, (payload: any) => callBack(payload));
};

export const leaveEvenMobile = (event: string, id?: number): void => {
	if (!id) return console.log('leaveEventMobile - нет id', event);
	window.Echo.private(`mobile-scan.operator.${id}`).stopListening(event);
};
