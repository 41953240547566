import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import ModalScannedCounter from '@pages/main/components/modal-scanned-counter';
import { IMailTask } from '@store/tasks';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ModalPostals: FC = () => {
	const { print, tasks } = useStore();

	const columns: ColumnsType<IMailTask> = [
		{
			dataIndex: 'postal',
			width: 350,
			title: 'Посылка',
			render: (_, { number }) => <span>{number}</span>,
		},
		{
			dataIndex: 'dimensions',
			width: 200,
			title: 'Габариты',
			render: (_, { width, height, length }) => {
				return <span>{width === 0 && height === 0 && length === 0 ? '' : `${width}*${height}*${length} см`}</span>;
			},
		},
		{
			dataIndex: 'cell',
			width: 200,
			title: 'Ячейка',
			render: (_, { store_place_item_full_name }) => <span>{store_place_item_full_name || ''}</span>,
		},
	];

	const close = (): void => tasks.setShowPostalsModal(false);

	return (
		<ModalScannedCounter
			onClose={close}
			isShow={tasks.showPostalsModal}
			title={
				<span>
					<Icon imageName="Mail" /> Все посылки
				</span>
			}
			footer={
				<div className={styles.footer}>
					<Button
						onClick={() =>
							print.setPrintPreview({ type: 'postal-for-collect', ids: tasks?.filteredPostals.map(({ id }) => id) })
						}
					>
						Печать списка посылок
					</Button>
					<Button
						onClick={tasks.postalCollect.bind(null, tasks.scannedPostals)}
						type="primary"
						disabled={!tasks.scannedPostals.length}
					>
						Завершить сборку
					</Button>
				</div>
			}
			description=""
			scannedCounter={tasks.scannedPostals.length}
			totalCounter={tasks.filteredPostals.length}
		>
			<Table
				rowKey="id"
				pagination={false}
				className={styles.table}
				dataSource={tasks.filteredPostals}
				columns={columns}
				onRow={({ isScan }) => ({
					className: cn(styles.row, { [styles.row__check]: isScan }),
				})}
			/>
		</ModalScannedCounter>
	);
};

export default observer(ModalPostals);
