import colors from '@config/colors';
import { ConfigProvider, DatePicker as AntDatePicker, DatePickerProps as AntDatePickerProps } from 'antd';
import cn from 'classnames';
import { CSSProperties, FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

const DatePicker: FC<
	AntDatePickerProps & {
		wrapperStyle?: CSSProperties;
		required?: boolean;
		errorMessage?: string | null;
	}
> = ({ wrapperStyle, id, placeholder, className, required = false, value, errorMessage = null, ...props }) => {
	const [showPlaceholder, setShowPlaceholder] = useState(!value);

	useEffect(() => {
		setShowPlaceholder(!value);
	}, [value]);

	return (
		<ConfigProvider
			theme={{
				token: {
					controlHeight: 38,
					borderRadius: 2,
					colorBgContainerDisabled: '#fff',
					colorTextDisabled: colors.grey1,
				},
			}}
		>
			<div className={cn(className, styles.wrap, { [styles.wrap__error]: errorMessage })} style={wrapperStyle}>
				<AntDatePicker
					{...props}
					format={['DD.MM.YYYY']}
					placeholder=" "
					value={value}
					allowClear={false}
					onOpenChange={(open) => setShowPlaceholder(open ? false : !value)}
					status={errorMessage ? 'error' : undefined}
				/>
				{placeholder && (
					<label className={cn(styles.label, { [styles.label_placeholder]: showPlaceholder })} htmlFor={id}>
						{placeholder} {required && <span className={styles.asterisk}>*</span>}
					</label>
				)}
				{errorMessage && <span className={styles.error}>{errorMessage}</span>}
			</div>
		</ConfigProvider>
	);
};

export default DatePicker;
