import { RootStore } from '@store/index';
import { getRoot, types } from 'mobx-state-tree';
import { removeMaskEmall } from 'utils/removeMaskEmall';

import { voiceMessage } from '../../../utils/voiceMessage';
import ConsolidationModel from './consolidation';
import GoodsModel from './goods';

const { model, union, literal, optional } = types;

const Emall = model('emall', {
	tab: optional(union(literal('consolidation'), literal('goods')), 'consolidation'),
	goods: GoodsModel,
	consolidation: ConsolidationModel,
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		setTab(tab: 'consolidation' | 'goods') {
			self.tab = tab;
		},
		handleScanner(evt: KeyboardEvent) {
			const { modalMoving, modalCreateSpace, modalGoodsTable } = self.consolidation;

			root.scanner.handleScanner(evt, (value) => {
				if (value === 'NEWCELL') {
					voiceMessage({ message: 'Выберите ячейку' });
					self.goods.setIsNewCell(true);
					return;
				}
				if (self.goods.surplusItems?.length) return self.goods.scanPlaceItemBarcode(+value);

				modalMoving.show && modalMoving.setInput(removeMaskEmall(value));

				if (modalCreateSpace.show) {
					if (modalCreateSpace.goods) return modalCreateSpace.setSign(value);
					modalCreateSpace.addGoods(+value);
				}

				if (modalGoodsTable?.show) {
					if (modalGoodsTable.deleteId) return modalGoodsTable.deleteItem(value);
					if (modalGoodsTable.showConfirm) {
						modalGoodsTable.setSign(removeMaskEmall(value));
						modalGoodsTable.submit();
						return;
					}
					modalGoodsTable.scanBarcode(value).catch((err) => console.log('consolidation scanBarcode err:', err));
				}
			});
		},
	};
});

export default Emall;
