import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const Header: FC = () => {
	const { tasks } = useStore();

	return (
		<>
			<header className={styles.header}>
				<span className={styles.receiver}>{tasks && tasks.ordersCollectBags?.receiver_fio}</span>
				<span className={styles.counter}>
					<span className={styles.counter__current}>{(tasks && tasks.countCollectedOrdersBags) || 0}</span>
					<span>/{(tasks && tasks.ordersCollectBags?.count) || 0}</span>
				</span>
			</header>
		</>
	);
};

export default observer(Header);
