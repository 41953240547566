import { api } from '@http/index';
import { IRoutingInfo, PutSpaceDataType } from '@store/routing-info';
import { IGoods, IGoodsSpace } from '@store/shipment/emall/consolidation';
import { ScanResultType, SurplusItemType } from '@store/shipment/emall/goods';
import { IProduct } from '@store/shipment/emall/product';
import { IHeader } from '@store/shipment/header';
import { Sheet } from '@store/shipment/route-sheet';
import { AxiosResponse } from 'axios';

export interface IProductScan extends IProduct {
	scanning_status: 1 | 2;
	scanning_message: string;
}

export interface IPostEmallItemScan {
	scan_result: ScanResultType;
	items: IProductScan[];
}

export interface IPostEmallSurplusItems {
	scan_result: ScanResultType;
	items: SurplusItemType[];
}

const getLists = (showOld: 0 | 1): Promise<AxiosResponse<Sheet[]>> =>
	api.get(`/routing/shipment/lists?show_old=${showOld}`);

const getList = (id: number): Promise<AxiosResponse<IRoutingInfo>> => api.get(`/routing/shipment/lists/${id}`);

const postDriver = (
	id: number,
	data: { driver_market_id: number | null; is_mercenary?: 0 | 1 }
): Promise<AxiosResponse<void>> => api.post(`/routing/shipment/lists/${id}/driver-update`, data);

const getInfo = (): Promise<AxiosResponse<IHeader>> => api.get(`/routing/shipment/info`);

const putSpaces = (id: number, data: PutSpaceDataType): Promise<AxiosResponse<IRoutingInfo>> =>
	api.put(`/routing/shipment/spaces/${id}`, data);

const getEmallItems = (): Promise<AxiosResponse<IProduct[]>> => api.get(`/routing/shipment/emall/items`);

const postEmallItemScan = (data: {
	barcode: string;
	count: number;
	routing_space_id: number;
}): Promise<IPostEmallItemScan> => api.post(`/routing/shipment/emall/items/scan`, data);

const deleteEmallItem = (id: number): Promise<AxiosResponse<void>> => api.delete(`/routing/shipment/items/${id}`);

const getEmallSpaces = (): Promise<AxiosResponse<IGoods[]>> => api.get(`/routing/shipment/emall/spaces`);

const postEmallSpaces = (data: {
	type: number;
	number?: string;
	barcode?: string | null;
	count?: number;
}): Promise<AxiosResponse<{ id: string }>> => api.post(`/routing/shipment/emall/spaces`, data);

const getEmallSpaceId = (id: number): Promise<AxiosResponse<IGoodsSpace>> =>
	api.get(`/routing/shipment/emall/spaces/${id}/items`);

const deleteSpace = (id: number): Promise<AxiosResponse<void>> => api.delete(`/routing/shipment/spaces/${id}`);

const putItems = (
	id: number,
	data: {
		routing_space_id?: number | null;
		is_scanned: 0 | 1;
		count?: number;
		barcode?: string;
	}
): Promise<AxiosResponse<void>> => api.put(`/routing/shipment/items/${id}`, data);

const putItemsMoving = (data: {
	space_number_old: string;
	space_number_new: string;
}): Promise<AxiosResponse<IGoods[]>> => api.put(`/routing/shipment/items/moving`, data);

const confirmList = (id: number, data: { driver_market_id: number }): Promise<AxiosResponse<void>> =>
	api.post(`/routing/shipment/lists/${id}/transmit`, data);

const postReleaseDriver = (id: number): Promise<AxiosResponse<void>> =>
	api.post(`/routing/shipment/lists/release-driver`, { driver_id: id });

const postEmallSurplusItems = (data: {
	barcode: string;
	count: number;
	bag_name?: string;
	order_market_id?: number | null;
}): Promise<AxiosResponse<IPostEmallSurplusItems>> => api.post(`/routing/shipment/emall/surplus-items`, data);

export const ShipmentService = {
	getLists,
	getInfo,
	getList,
	putSpaces,
	getEmallItems,
	getEmallSpaces,
	getEmallSpaceId,
	deleteEmallItem,
	deleteSpace,
	putItems,
	putItemsMoving,
	postDriver,
	confirmList,
	postEmallSpaces,
	postReleaseDriver,
	postEmallItemScan,
	postEmallSurplusItems,
};
