import { useStore } from '@hooks/useStore';
import { Modal, QRCode } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

import styles from './index.module.scss';

interface IProps {
	isShow: boolean;
	setIsShow: (val: boolean) => void;
}

const ModalQR: FC<IProps> = ({ isShow, setIsShow }) => {
	const store = useStore();

	return (
		<Modal
			centered
			footer={false}
			title="Отсканируйте QR код для получения мобильных оповещений"
			open={isShow}
			onCancel={() => setIsShow(false)}
		>
			<QRCode
				size={300}
				className={styles.qr}
				value={`https://${window.location.host}/notification/${dayjs().format(
					'YYYY-MM-DD'
				)}/${store.operator?.id.toString()}`}
			/>
		</Modal>
	);
};

export default ModalQR;
