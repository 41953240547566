import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import ItemsTable from '@pages/main/visits/components/visit/compoments/modal-order-collect-bags/components/table';
import OrderService from '@services/order';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Header from 'pages/main/visits/components/visit/compoments/modal-order-collect-bags/components/header';
import { useState } from 'react';

import { playAudio } from '../../../../../../../utils/playSound';
import styles from './index.module.scss';

const ModalOrderCollectBags = () => {
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

	const visit = useCurrentVisit();
	const store = useStore();

	const closeCollect = async (): Promise<void> => {
		if (visit && !isOpenModal && visit?.ordersCollectBags?.bags.length !== visit.countCollectedOrdersBags) {
			setIsOpenModal(true);
			return;
		}

		store.setLoading(true);

		try {
			await OrderService.postOrderBagsCollect(visit?.ordersCollectBags?.order_ids || []);
			await visit?.setStartLoadData(
				{
					id: visit?.orders[0]?.id,
					type: 'order',
				},
				null
			);

			setIsOpenModal(false);
			visit?.closeModalOrderCollectBags();
			playAudio('posted');
		} catch (err: any) {
			playAudio('error');
			store.notice.setNotice({ message: err?.error || err?.message || '' });
		} finally {
			store.setLoading(false);
		}
	};

	return (
		<Modal
			title={
				<div className={styles.title}>
					{visit?.ordersCollectBags?.bags.length !== 1
						? 'Все заказы клиента'
						: `Заказ №${visit?.ordersCollectBags?.bags[0]?.order_market_id}`}
				</div>
			}
			open={!!visit?.ordersCollectBags}
			width={720}
			footer={
				<div className={styles.footer}>
					<Button
						onClick={() =>
							store.print.setPrintPreview({
								type: 'bags-for-collect',
								ids: visit?.ordersCollectBags?.order_ids.map((el) => el) || [],
							})
						}
					>
						Печать списка товаров
					</Button>
					<Button onClick={closeCollect} type="primary">
						Завершить сборку
					</Button>
				</div>
			}
			onCancel={visit?.closeModalOrderCollectBags}
			className={styles.body}
			classNameModal={styles.modal}
			centered
			destroyOnClose
		>
			<Header />
			<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
				<ItemsTable />
			</OverlayScrollbarsComponent>
			<Modal
				onCancel={() => setIsOpenModal(false)}
				classNameModal={styles.modal_confirm}
				width={500}
				destroyOnClose
				centered
				open={isOpenModal}
				footer={false}
				title={false}
			>
				<span className={styles.text}>Отсканированы не все пакеты заказа!</span>
				<div className={styles.buttons}>
					<Button onClick={closeCollect}>Завершить</Button>
					<Button onClick={() => setIsOpenModal(false)} type="primary">
						Досканировать
					</Button>
				</div>
			</Modal>
		</Modal>
	);
};

export default observer(ModalOrderCollectBags);
